import {createContext, useState, useEffect, useContext} from "react";

import {useNavigate} from "react-router-dom";

import Apiurl from "../ConstURL";

const AuthContext = createContext()


export const AuthProvider = ({children}) => {
    const navigate = useNavigate()
    var token = localStorage.getItem("token")
    const Check = fetch(Apiurl + "/accounts?token=" + token)
        .then((response) => {
            return response.status;
        })

    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)

    useEffect(() => {
        //setLoading(false)
        checkUserStatus()

    }, [])

    const loginUser = async (userInfo) => {
        setLoading(true)

        console.log('userInfo', userInfo)
        var token = localStorage.getItem("token")
        console.log(token)
        try {
            //let response = await account.createEmailSession(userInfo.email, userInfo.password)
            const Check1 = await fetch(Apiurl + "/accounts?token=" + token)
                .then((response) => {
                    if (response.status===200){
                        setUser(true)
                    }else {
                        setUser(null)
                    }

                })




        } catch (error) {
            console.error(error)
        }
        setLoading(false)

    }

    const logoutUser = async () => {

        setUser(null)
    }


    const checkUserStatus = async () => {
        try {

            let accountDetails = await Check;
            if (accountDetails===200) {
                setUser(true)
            } else {
                console.log("logout")
            }
        } catch (error) {

        }
        setLoading(false)
    }

    const contextData = {
        user,
        loginUser,
        logoutUser,
    }

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? <p>Loading...</p> : children}
        </AuthContext.Provider>
    )
}

//Custom Hook
export const useAuth = () => {
    return useContext(AuthContext)
}

export default AuthContext;