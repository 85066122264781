import {Component, useEffect, useState} from "react";

import {
    AutoComplete,
    Button,
    Card,
    ConfigProvider,
    Flex,
    Form,
    DatePicker,
    Input,
    Layout,
    message,
    Row,
    Select, Space, Spin,
    Table, Tag,
    Upload, Checkbox
} from "antd";
import Apiurl from "../ConstURL";
import axios, {options} from "axios";
import {
    FileExcelOutlined, InboxOutlined,
} from '@ant-design/icons';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';

const {RangePicker} = DatePicker;

export default function Addtask() {
    const [form] = Form.useForm()

    const [time_from, Set_time_from] = useState("")
    const [time_to, Set_time_to] = useState("")

    const [filter_filter, Set_filter_filter] = useState("")
    const [AccountId, setAccountId] = useState("")

    const [currentValue, setCurrentValue] = useState('')
    const [AllAccounts, setAllAccounts] = useState([]);
    const [AllTypesMas, setAllTypesMas] = useState([]);

    const [Loading, setLoading] = useState(false);

    const [tasks, settasks] = useState([])
    const [taskscount, settaskscount] = useState(0)

    const columns = [
        {
            title: 'Internal Id',
            dataIndex: 'internalid',
            key: 'internalid',

        },
        {
            title: 'Reference numbernumber',
            dataIndex: 'referencenumber',
            key: 'referencenumber',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },{
            title: 'Total amount',
            dataIndex: 'totalamount',
            key: 'totalamount',
        },{
            title: 'Amount due',
            dataIndex: 'amountdue',
            key: 'amountdue',
        },{
            title: 'Due date',
            dataIndex: 'duedate',
            key: 'duedate',
        },{
            title: 'Tran date',
            dataIndex: 'trandate',
            key: 'trandate',
        },{
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },{
            title: 'Customer number',
            dataIndex: 'customernumber',
            key: 'customernumber',
        },
    ];

    const onChangeFilterfilter_date = (text) => {
        setLoading(true)
        console.log(new Date().getTimezoneOffset())
        if (text) {
            console.log(text[0].format('YYYY-MM-DD'))
            console.log(text[1].format('YYYY-MM-DD'))
            Set_time_from(text[0].format('YYYY-MM-DD'))
            Set_time_to(text[1].format('YYYY-MM-DD'))

            fetch(Apiurl + "/invoices/api?" + new URLSearchParams({
                token: localStorage.getItem("token"),
                account_id:AccountId,
                from_time: text[0].format('YYYY-MM-DD'),
                to_time: text[1].format('YYYY-MM-DD'),
                time_offset: new Date().getTimezoneOffset().toString(),

            }))
                .then(response => {
                    return response.json()
                })
                .then(data => {

                    settaskscount(data.Allcount)
                    settasks(data.Data)
                    setLoading(false)
                })

        } else {
            Set_time_from("")
            Set_time_to("")
            fetch(Apiurl + "/invoices/api?" + new URLSearchParams({
                token: localStorage.getItem("token"),
                account_id:AccountId,
                from_time: "",
                to_time: "",
                time_offset: new Date().getTimezoneOffset().toString(),

            }))
                .then(response => {
                    return response.json()
                })
                .then(data => {

                    console.log(data.Allcount)
                    settaskscount(data.Allcount)
                    settasks(data.Data)
                    setLoading(false)
                })
        }
    };

    const rangePresets = [
        {
            label: 'За 7 дней',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'За 14 дней',
            value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
            label: 'За 30 дней',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'За 90 дней',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ];

    const onChangeFilterWorkerId = (text) => {
        if (text) {
            text = text
        } else {
            text = ""
        }

        setAccountId(text)


        fetch(Apiurl + "/invoices/api?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            account_id:text,
            from_time: time_from,
            filter_sort: filter_filter,
            to_time: time_to,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)
                settaskscount(data.Allcount)
                settasks(data.Data)
            })

    };

    const onChangeFilterfilter_filter = (text) => {
        text = text.target.checked
        if (text){
            text="1"
        }else {
            text=""
        }

        Set_filter_filter(text)
        fetch(Apiurl + "/invoices/api?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            account_id: AccountId,
            from_time: time_from,
            to_time: time_to,
            filter_sort:text,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)
                settaskscount(data.Allcount)
                settasks(data.Data)
            })


    };

    useEffect(()=>{
        axios.get(Apiurl+"/accounts?token="+localStorage.getItem("token")).then((res: any) => {

            // res.data.Data.forEach(item => {
            //     mas.push({label: item, value: item})
            // })
            // var mas = []
            // setAllTypesMas()

            setAllAccounts(res.data.Data)

        }).catch((err: Error) => {
            console.log(err)
        })


    },[]);

    const disabledRangeTime = (_, type) => {
        if (type === 'start') {
            return {

                disabledSeconds: () => [55, 56],
                disabledMinutes: () => [55, 56],
            };
        }
        return {
            disabledSeconds: () => [55, 56],
            disabledMinutes: () => [55, 56],
        };
    };


    return (<Flex vertical={false} justify={"center"} style={{paddingTop: "7px"}} >

            <Space type="flex" size='large' justify="center" align="center" direction="vertical"
            style={{padding: "20px"}}>
                <Flex gap={"large"} justify={"center"} style={{paddingTop: "20px"}}>

                    {AllAccounts.map((account, index) => (
                        account.AccountStatus ? <Tag color={"green"}>{account.Username}</Tag> : <Tag color={"red"}>{account.Username}</Tag>
                    ))}

                </Flex>

                <Flex gap={"large"} justify={"center"} style={{paddingTop: "15px"}}>
                    <RangePicker disabledTime={disabledRangeTime} format="DD.MM.YYYY" onChange={onChangeFilterfilter_date}
                                 presets={rangePresets}/>
                    <Select
                        style={{width: 200}}
                        placeholder={"Аккаунт"}
                        allowClear={true}
                        onChange={onChangeFilterWorkerId}
                        options={
                            AllAccounts.map((accobject, indexch) => {
                                return {value: accobject.Id, label: accobject.Username}
                            })
                        }
                    >
                    </Select>
                    <Checkbox onChange={onChangeFilterfilter_filter}>Сначала старые</Checkbox>
                </Flex>
                {/*<div>{taskscount}</div>*/}
                <Table
                    style={{paddingTop:"25px"}}
                    loading={Loading}
                    columns={columns}
                    pagination={{
                        showSizeChanger: true,
                        showTotal: () => `Найдено ${taskscount}`,

                        position: "Center",
                        pageSizeOptions: [10,20,30,40,50,100]
                    }}
                    dataSource={tasks}
                />
                {taskscount!==0 && <div style={{paddingTop:"0px"}}><Button  type={"primary"} icon={<FileExcelOutlined/>}
                                         rel="noreferrer" htmlType={"a"} target="_blank"
                                         href={Apiurl+"/invoices/api/excel?"+ new URLSearchParams({
                                             token: localStorage.getItem("token"),
                                             account_id: AccountId,
                                             from_time: time_from,
                                             to_time: time_to,
                                             filter_sort: filter_filter,
                                             time_offset: new Date().getTimezoneOffset().toString(),

                                         })}>Выгрузить Excel</Button></div>
                }
        </Space>
        </Flex>

    );

}